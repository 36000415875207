import { Box, Card, CardContent, Fade } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { CapitalizeFirstLetterComp } from 'src/pages/common/HOC/CapitalizeFirstLetterComp'
import { TruncateWraper } from 'src/pages/common/HOC/TruncateWraper'
import { capitalizeFirstLetter } from 'src/utils'
import { BadgePill } from '../../../BadgePill/BadgePill'
import { HtmlFormatter } from '../../../HtmlFormatter'
import NoLength from '../../../NoLength/NoLength'
import { RoundDot } from '../../../RoundDot/RoundDot'
import { PuffSpinner } from '../../../spinner/puff/puff'


const colorStyle = {
    color: `black`
}

const ReporterDesc = ({ isComment, task, isMissed }) => {
    const decideAssignedBy = () => {
        console.log(typeof task?.assignedBy, `ininininin`, task)
        if (typeof task?.assignedBy === `string`) {

            console.log(`ininininin`)
            return task?.assignedBy
        }
        else if (typeof task?.assignedBy === `object`) {

            console.log(`ininininin`)
            return `${task?.assignedBy?.firstName} ${task?.assignedBy?.lastName}`
        }
        return ``
    }
    const decidehasOrHad = isMissed ? `had` : `has`
    return (<Box sx={{ fontSize: `10px`, color: `rgb(22, 153, 197)` }}>
        <Box sx={{ display: `inline`, fontWeight: `600`, mr: .5 }}>
            Reporter: <CapitalizeFirstLetterComp>
                {decideAssignedBy()}
            </CapitalizeFirstLetterComp>
        </Box>
        <Box sx={{ display: `block`, color: `black`, fontWeight: 600 }}>
            {isComment ? `A new comment has been added on this task!` : `${decidehasOrHad} assigned you a new task!`}
        </Box>
        {isMissed && !isComment && <Box sx={{ ...colorStyle, mt: .5 }}>
            <RoundDot color={`red`} style={{ fontSize: `6px`, verticalAlign: `middle` }} /> You have missed Task Alert for this task
        </Box>}

    </Box>)
}


export const TasksNotifications = ({ setOpenSpecificTask, setSelectedTask }) => {
    const { tasksManager: { taskNotificationArray, tasksLoading } } = useSelector((state) => state)
    return (
        <div>
            {tasksLoading?.loading ? <PuffSpinner /> : null}
            {taskNotificationArray?.length ? taskNotificationArray?.map((task) => (
                <Fade mountOnEnter unmountOnExit in={!!task} key={task.id}>
                    <Card onClick={() => {
                        setOpenSpecificTask((p) => !p);
                        setSelectedTask(task);
                    }} sx={{ boxShadow: `0 0px 2px #9c909085`, borderRadius: 0 }}>
                        <CardContent style={{ padding: `12px` }}>
                            <div
                                style={{
                                    fontWeight: `600`,
                                    fontSize: `13px`,
                                    color: `rgb(22, 153, 197)`,
                                    wordWrap: `break-word`
                                }}
                            >
                                {task?.taskName}
                            </div>
                            {task?.isNewCommentAdded ? <BadgePill type={task?.taskStatus === `opened` ? `badge-success` : `badge-danger`} >{capitalizeFirstLetter(task?.taskStatus)}</BadgePill> : null}

                            {!task?.isNewCommentAdded && <TruncateWraper tag='div'
                                tagStyles={{
                                    fontSize: `10px`,
                                    maxWidth: `90%`,
                                    WebkitLineClamp: `1`,
                                    color: `rgb(22, 153, 197)`,
                                    marginBottom: `8px`,
                                    wordWrap: `break-word`
                                }}
                            >
                                <HtmlFormatter title={task?.taskDescription} />
                            </TruncateWraper>}

                            <ReporterDesc task={task} isComment={task?.isNewCommentAdded} isMissed={!task?.taskReminderNotificationReceived} />

                        </CardContent>
                    </Card>
                </Fade>
            )) : <NoLength phrase={`No Tasks!`} style={{ p: 1 }} />
            }
        </div>
    )
}