import React, { useState, useEffect, useRef } from 'react';
import '@reach/combobox/styles.css';
import css from './editPatient.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { resmedGroupedOptions, respironicsGroupedOptions, fisherAndPaykelGroupedOptions } from '../../importPatient/data';
import { momentWrapper } from 'src/momentWrapper';
import { PuffSpinner } from '../../../../../../common/components/spinner/puff/puff';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { updateCreatedAt, updatePatientMask, updateEditPatient } from 'src/redux/patient/patientActions';
import MenuItem from '@mui/material/MenuItem';
import { getRequest, PATIENT_WITH_OUT_DEVICE_DME_URL, PATIENT_UPDATE_URL, patchRequest } from '../../../../../../../crud/crud.js';
import { Box, ListSubheader, TextField, Grid, Select, FormControl, InputLabel, InputAdornment, Zoom } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import {
  isDme,
  resmedMaskTypes,
  respironicsMaskTypes,
  fisherMaskTypes,
  patientRecurringReactivationOptions,
  setPhoneOnBlurHandler,
  dummyPhonePlaceHolder,
  fieldValidationPhrase,
} from 'src/utils';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { InputSpinner } from 'src/pages/common/components/InputSpinner/InputSpinner';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { DateFormat } from 'src/pages/common/components/DateFormat/DateFormat';
import Downshift from 'downshift';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';
import { emailRegex, phoneRegex } from 'src/regexes';

export const EditPatient = ({ patientId, editPatientModal, setEditPatientModal, set_edit_patient_rerender_flag }) => {
  const selectedPatient = useSelector((state) => state.patient?.patientData?.data);
  const [inputsToEdit, setInputsToEdit] = useState({});
  const [payloadRedux, setPayLoadRedux] = useState({});
  const [loading, setLoading] = useState(false);
  const [secondaryDiagnosisLoading, setSecondaryDiagnosisLoading] = useState(false);
  const [isPrimaryOpenDropDown, setIsPrimaryOpenDropDown] = useState(false);
  const [primaryDiagnosisIsLoading, setPrimaryDiagnosisLoading] = useState(false);
  const [secDiagnosisListItems, setSecDiagnosisListItems] = useState([]);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [primaryDiagnosisListItems, setPrimDiagnosisListItems] = useState([]);
  const [anchorElements, setAnchorElements] = useState({
    primary: null,
    secondary: null,
  });

  const reactivationValueSetter = (value, recurringActivationMonth) => {
    setPayLoadRedux((p) => ({ ...p, status: { ...p?.status, recurringPeriod: recurringActivationMonth } }));
    setInputsToEdit((p) => ({ ...p, recurringPeriod: recurringActivationMonth }));
  };
  const reactivationValueSetterForDate = (value, nextActivationOn) => {
    setPayLoadRedux((p) => ({ ...p, status: { ...p?.status, nextActivationOn: nextActivationOn } }));
    setInputsToEdit((p) => ({ ...p, reactivateAt: nextActivationOn }));
  };

  //errors
  const [errors, setErrors] = useState({
    genderError: ``,
    phoneError: ``,
    primaryDiagnosisError: ``,
    emailError: ``,
    statusError: ``,
    notesError: ``,
    manufacturerError: ``,
    maskTypeError: ``,
    maskSizeError: ``,
  });
  //Refs
  const close = useRef(null);
  const phoneRef = useRef(null);
  const inputPDiagnosis = useRef(null);
  const genderRef = useRef(null);
  const statusRef = useRef(null);
  const emailRef = useRef(null);
  const notesRef = useRef(null);
  const manufacturerRef = useRef(null);
  const typeRef = useRef(null);
  const sizeRef = useRef(null);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    const patientDetails = { ...selectedPatient };
    patientDetails[`phoneNumber`] = null;
    if (!patientDetails?.phoneNumber) {
      patientDetails[`phoneNumber`] = dummyPhonePlaceHolder;
    }
    setPayLoadRedux(selectedPatient);
  }, [selectedPatient]);

  const anchorElemHandler = (name, elem) => {
    setAnchorElements((p) => ({ ...p, [name]: elem }));
  };

  const comBosPrimaryDiagnosisOnChange = (value) => {
    setPayLoadRedux({ ...payloadRedux, primaryDiagnosis: value });
    getNIHPrimaryDiagnosis(value);
  };

  // primaryDiagnosisDropdownValues Change.
  const primaryDiagnosisDropdownValueOnChange = (selectedDiagnosis) => {
    setIsPrimaryOpenDropDown((pre) => !pre);
    setPayLoadRedux({ ...payloadRedux, primaryDiagnosis: selectedDiagnosis.Description });
    setInputsToEdit({ ...inputsToEdit, primaryDiagnosis: selectedDiagnosis.Description });
  };

  // GET LIST OF NIH PRIMARY DIAGNOSIS
  const getNIHPrimaryDiagnosis = (query) => {
    if (query.length) {
      setPrimaryDiagnosisLoading(true);
      getRequest(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${query}`)
        .then((response) => {
          setPrimaryDiagnosisLoading(false);
          setIsPrimaryOpenDropDown((pre) => !pre);
          const listOfDiagnosis = response?.data[3].map(([Code, Description]) => ({ Code, Description }));
          setPrimDiagnosisListItems(listOfDiagnosis);
          setIsPrimaryOpenDropDown(true);
        })
        .catch(() => {
          setPrimaryDiagnosisLoading(false);
        });
    }
  };

  const secondaryDiagnosisDropdownValueOnChange = (selectedDiagnosis) => {
    setPayLoadRedux({ ...payloadRedux, secondaryDiagnosis: selectedDiagnosis.Description });
    setInputsToEdit({ ...inputsToEdit, secondaryDiagnosis: selectedDiagnosis.Description });
    setIsOpenDropDown((pre) => !pre);
  };

  const comBosSecondaryDiagnosisOnChange = (value) => {
    setPayLoadRedux({ ...payloadRedux, secondaryDiagnosis: value });
    setInputsToEdit({ ...inputsToEdit, secondaryDiagnosis: value });
    getNIHSecondaryDiagnosis(value);
  };

  // GET NIH SECONDARY DIAGNOSIS
  const getNIHSecondaryDiagnosis = (query) => {
    if (query?.length) {
      setSecondaryDiagnosisLoading(true);
      getRequest(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${query}`)
        .then((response) => {
          const listOfDiagnosis = response?.data[3].map(([Code, Description]) => ({ Code, Description }));
          setSecondaryDiagnosisLoading(false);
          // setSecondaryDiagnosisQuery("")
          setSecDiagnosisListItems(listOfDiagnosis);
          setIsOpenDropDown(true);
        })
        .catch(() => {
          setSecondaryDiagnosisLoading(false);
          // setSecondaryDiagnosisQuery("")
        });
    }
  };

  const validateInput = async (field) => {
    switch (field) {
      case `gender`:
        if (!genderRef.current.value) {
          setErrors({ ...errors, genderError: `Required` });
        } else {
          setErrors({ ...errors, genderError: `` });
        }
        break;
      case `phone`:
        if (!phoneRegex.test(phoneRef.current.value)) {
          setErrors({ ...errors, phoneError: `Invalid No. number should neither be composed of text nor be a non-American` });
        } else {
          setErrors({ ...errors, phoneError: `` });
        }

        break;
      case `email`:
        if (!emailRef?.current?.value) {
          setErrors({ ...errors, emailError: `` });
        } else if (emailRef.current.value) {
          if (!emailRegex.test(emailRef.current.value)) {
            setErrors({ ...errors, emailError: `Invalid Email Address` });
          } else {
            setErrors({ ...errors, emailError: `` });
          }
        }
        break;
      case `primaryDiagnosis`:
        if (!inputPDiagnosis?.current?.value) {
          setErrors({ ...errors, primaryDiagnosisError: `*Required` });
        } else if (inputPDiagnosis?.current?.value?.length > 0) {
          setErrors({ ...errors, primaryDiagnosisError: `` });
        }
        break;
      case `status`:
        if (!statusRef?.current?.value) {
          setErrors({ ...errors, statusError: `*Required` });
        } else {
          setErrors({ ...errors, statusError: `` });
        }
        break;
      case `notes`:
        if (notesRef.current?.value?.length > 570) {
          setErrors({ ...errors, notesError: `Should not be greater than 570 words` });
        } else {
          setErrors({ ...errors, notesError: `` });
        }
        break;
      case `size`:
        if (!sizeRef?.current?.value) {
          setErrors({ ...errors, maskSizeError: `*Required` });
        } else {
          setErrors({ ...errors, maskSizeError: `` });
        }
        break;
      case `manufacturer`:
        if (!manufacturerRef.current.value) {
          setErrors({ ...errors, maskSizeError: ``, maskTypeError: ``, manufacturerError: `` });
        } else if (manufacturerRef.current.value) {
          setErrors({ ...errors, maskSizeError: `*Required`, maskTypeError: `*Required`, manufacturerError: `` });
        }
        break;
      default:
        return true;
    }
  };

  const submitHandler = async () => {
    try {
      const successValidation = await validateInput();
      if (successValidation) {
        let payLoad = { ...inputsToEdit };
        const splittingDaysInWIP = payLoad?.daysInWIP && payLoad?.daysInWIP.split(`_`);
        let daysInWIPResult = payLoad?.daysInWIP && Number(splittingDaysInWIP[0]);

        if (payLoad?.daysInWIP) {
          payLoad = { ...payLoad, daysInWIP: daysInWIPResult };
        } else {
          if (inputsToEdit?.reactivateAt) {
            payLoad = { ...inputsToEdit };
            delete payLoad[`reactivateAt`];
          } else {
            payLoad = { ...inputsToEdit };
          }
        }

        if (payLoad?.phoneNumber === dummyPhonePlaceHolder) {
          payLoad = { ...payLoad, phoneNumber: null };
        }

        let query = inputsToEdit?.reactivateAt ? `/?reactivateAt=${momentWrapper(inputsToEdit?.reactivateAt).toISOString()}` : `?reactivateAt=false`;
        let url = isDme() ? PATIENT_WITH_OUT_DEVICE_DME_URL + patientId : PATIENT_UPDATE_URL + patientId + query;
        setLoading(true);

        if (payLoad.status !== `recurringReactivation`) {
          delete payLoad.recurringPeriod;
        }

        patchRequest(url, payLoad)
          .then((res) => {
            dispatchRedux(updateEditPatient(res?.data?.updatePatient));
            dispatchRedux(updatePatientMask(res?.data?.updatePatient));
            dispatchRedux(updateCreatedAt(res?.data?.updatePatient?.createdAt));
            setInputsToEdit({});
            set_edit_patient_rerender_flag((edit_rerender_counter) => edit_rerender_counter + 1); // RE-RENDER CONDITIONAL BASIC-INFO COMPONENT
            setLoading(false);
            successToast(`Edit patient successfully`);
            setEditPatientModal(false);
            close.current.click();
          })
          .catch((err) => {
            // if (err?.response) {
            //   let responseText = err?.response?.data?.message;
            //   toast.error(() => <>{responseText?.charAt(0).toUpperCase().concat(responseText.slice(1, responseText?.length))}</>, {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //   });
            // }
            setLoading(false);
          });
      } else {
        toast.error(() => <>{fieldValidationPhrase}</>, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error(() => <>Failed</>, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const onChangeForValues = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    const copyPayloadRedux = { ...payloadRedux };
    const copyInputsToEdit = { ...inputsToEdit };

    if (name === `manufacturer`) {
      copyPayloadRedux[`mask`] = {
        [name]: value,
        type: ``,
        size: ``,
      };
      copyInputsToEdit[`mask`] = {
        [name]: value,
      };
    } else if (name === `size`) {
      copyPayloadRedux[`mask`] = {
        ...copyPayloadRedux.mask,
        [name]: value,
      };
      copyInputsToEdit[`mask`] = {
        ...copyInputsToEdit.mask,
        [name]: value,
      };
    } else if (name === `phoneNumber`) {
      let slicedNo = value.slice(0, 14);
      copyPayloadRedux[name] = slicedNo;
      copyInputsToEdit[name] = slicedNo;
    } else if (name === `status`) {
      copyPayloadRedux[`status`] = {
        [`helperStatus`]: value,
      };
      copyInputsToEdit[`status`] = value;
    } else if (name === 'emrPatientInternalId' || name === 'emrPatientExternalId') {
      copyPayloadRedux[name] = value;
      copyInputsToEdit[name] = value;
    } else {
      copyPayloadRedux[name] = value;
      copyInputsToEdit[name] = value;
    }

    setPayLoadRedux(copyPayloadRedux);
    setInputsToEdit(copyInputsToEdit);
  };

  const phoneMaskFunc = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);
    setPayLoadRedux((p) => ({ ...p, phoneNumber: temp }));
    setInputsToEdit((p) => ({ ...p, phoneNumber: temp }));
  };

  ///OnModalUnmount
  const onEditModalUnMount = () => {
    setEditPatientModal(false);
    setErrors({});
    setInputsToEdit({});
    setPayLoadRedux(selectedPatient);
  };

  const maskTypeSubHeaderStyles = {
    backgroundColor: `#1c72aae3`,
    color: `white`,
    textAlign: `center`,
    paddingTop: `0px`,
  };

  const maskTypeStyles = {
    backgroundColor: `#9fd1f9a8`,
    '&:hover': {
      backgroundColor: `rgba(25, 118, 210, 0.08)`,
    },
  };

  const InputLabelStyling = {
    left: `-2%`,
  };

  // Function for displaying a confirmation alert
  const alertForConfirmation = (selectedValueFromSelection) => {
    return Swal.fire({
      title: `Attention!`,
      text: `Are you sure want to modify patient activation status?`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonColor: `#3085d6`,
      cancelButtonColor: `#d33`,
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result?.isConfirmed) {
        onChangeForValues(selectedValueFromSelection)
        validateInput(`status`);
      }
    });
  };

  return (
    <CustomizedDialogs
      title="EDIT PATIENT"
      open={editPatientModal}
      setOpen={onEditModalUnMount}
      size="md"
      fullWidth={true}
      customButton={true}
      customButtonText="Save Patient"
      customButtonDisable={
        errors.phoneError ||
          errors.primaryDiagnosisError ||
          errors.genderError ||
          errors.emailError ||
          errors.statusError ||
          errors.notesError ||
          errors.manufacturerError ||
          errors.maskTypeError ||
          errors.maskSizeError
          ? true
          : false
      }
      customButtonAction={() => submitHandler()}
    >
      <ToastContainer />
      {/*  */}
      <div style={{ position: `static` }}>
        <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
          Basic Information
        </p>
        <hr style={{ margin: `0px` }} />
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container columnSpacing={1} rowSpacing={1.5}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size="small"
                disabled={true}
                type="text"
                sx={{
                  textTransform: `capitalize`,
                }}
                label={payloadRedux?.firstName}
                maxLength="25"
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size="small"
                label={payloadRedux?.lastName}
                type="text"
                sx={{
                  textTransform: `capitalize`,
                }}
                maxLength="25"
                disabled={true}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size="small"
                disabled={true}
                autoComplete="off"
                sx={{ opacity: `.6`, fontSize: `13px` }}
                value={momentWrapper(payloadRedux?.dateOfBirth).format(`MM/DD/YYYY`)}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <FormControl fullWidth>
                <InputLabel sx={InputLabelStyling}>Gender*</InputLabel>
                <Select
                  fullWidth
                  error={errors.genderError ? true : false}
                  onBlur={() => validateInput(`gender`)}
                  sx={{ height: `40px` }}
                  label="*Gender"
                  size="small"
                  // id={payloadRedux?.gender ? css.selectedSelector : css.selector}
                  onChange={(event) => {
                    onChangeForValues(event);
                  }}
                  value={payloadRedux?.gender}
                  inputProps={{
                    ref: genderRef,
                  }}
                  name="gender"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              <p className={errors.genderError ? `d-block text-danger` : `d-none`}>{errors.genderError ? errors.genderError : ``}</p>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size="small"
                inputProps={{
                  ref: phoneRef,
                }}
                autoComplete="off"
                onBlur={(event) =>
                  setPhoneOnBlurHandler(
                    event,
                    () => validateInput(`phone`),
                    (value) => {
                      setPayLoadRedux((p) => ({ ...p, phoneNumber: value }));
                      setInputsToEdit((p) => ({ ...p, phoneNumber: value }));
                    }
                  )
                }
                onChange={(e) => phoneMaskFunc(e.target.value)}
                value={payloadRedux?.phoneNumber}
                type="text"
                error={errors.phoneError ? true : false}
                label="Phone Number"
                name="phoneNumber"
              />
              <p className={errors.phoneError ? `d-block text-danger` : `d-none`}>{errors.phoneError ? errors.phoneError : ``}</p>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size="small"
                className={errors.emailError ? css.errorCustom : null}
                autoComplete="off"
                onChange={(e) => onChangeForValues(e)}
                value={payloadRedux?.email}
                type="email"
                name="email"
                label="Email"
                id="email"
                inputProps={{
                  ref: emailRef,
                }}
              />
              <p className={errors.emailError ? `d-block text-danger` : `d-none`}>{errors.emailError ? errors.emailError : ``}</p>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size="small"
                autoComplete="off"
                onChange={(event) => {
                  onChangeForValues(event);
                }}
                value={payloadRedux?.insuranceCompany}
                type="text"
                label="Insurance Company"
                name="insuranceCompany"
              />
            </Grid>
          </Grid>
        </Box>
        <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
          Clinical Information
        </p>
        <hr style={{ margin: `0px` }} />
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container rowSpacing={1.5} columnSpacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Downshift
                onChange={(eventValue) => {
                  primaryDiagnosisDropdownValueOnChange(eventValue);
                }}
                itemToString={(item) => (item ? item.Description : ``)}
                onBlur={() => {
                  setIsPrimaryOpenDropDown((p) => !p);
                  validateInput(`primaryDiagnosis`);
                }}
                inputValue={payloadRedux?.primaryDiagnosis}
              >
                {({ selectedItem, getInputProps, getItemProps, highlightedIndex }) => (
                  <div>
                    <div className="input-group mb-3">
                      <InputField
                        label="Primary Diagnosis Search*"
                        size="small"
                        type="text"
                        fullWidth={true}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{primaryDiagnosisIsLoading && <InputSpinner size="sm" />}</InputAdornment>,
                        }}
                        value={payloadRedux?.primaryDiagnosis}
                        error={errors?.primaryDiagnosisError ? true : false}
                        helperText={errors?.primaryDiagnosisError ? errors?.primaryDiagnosisError : null}
                        onChange={(event) => {
                          const { target, currentTarget } = event;
                          const { value } = target;
                          anchorElemHandler(`primary`, currentTarget);
                          comBosPrimaryDiagnosisOnChange(value);
                          validateInput(`primaryDiagnosis`);
                        }}
                        inputProps={{
                          ref: inputPDiagnosis,
                        }}
                      />
                    </div>
                    {isPrimaryOpenDropDown ? (
                      <div
                        id={css.downshiftDropdown}
                        style={{
                          overflowY: `scroll`,
                          maxHeight: `140px`,
                        }}
                      >
                        {!primaryDiagnosisListItems.length ? (
                          <div
                            id={css.dropdownItem}
                            style={{
                              backgroundColor: `rgb(251 251 251)`,
                              fontWeight: `normal`,
                              padding: `1px`,
                              borderBottom: `1px solid lightgrey`,
                              cursor: `pointer`,
                              color: `red`,
                            }}
                          >
                            No Data Found
                          </div>
                        ) : payloadRedux?.primaryDiagnosis === `` ? null : (
                          primaryDiagnosisListItems?.map((item, index) => (
                            <div
                              id={css.dropdownItem}
                              {...getItemProps({ key: index, index, item })}
                              style={{
                                backgroundColor: highlightedIndex === index ? `lightgrey` : `rgb(251 251 251)`,
                                fontWeight: selectedItem === item ? `bold` : `normal`,
                                padding: `1px`,
                                borderBottom: `1px solid lightgrey`,
                                cursor: `pointer`,
                              }}
                            >
                              {item?.Description}
                            </div>
                          ))
                        )}
                      </div>
                    ) : null}
                  </div>
                )}
              </Downshift>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Downshift
                onChange={(event) => {
                  secondaryDiagnosisDropdownValueOnChange(event);
                }}
                itemToString={(item) => (item ? item.Description : ``)}
                inputValue={payloadRedux?.secondaryDiagnosis}
              >
                {({ selectedItem, getInputProps, getItemProps, highlightedIndex }) => (
                  <div>
                    <div className="input-group mb-3">
                      <InputField
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{secondaryDiagnosisLoading && <InputSpinner size="sm" />}</InputAdornment>,
                        }}
                        value={payloadRedux?.secondaryDiagnosis}
                        size="small"
                        fullWidth={true}
                        label="Secondary Diagnosis Search"
                        onChange={(event) => {
                          const { target, currentTarget } = event;
                          const { value } = target;
                          anchorElemHandler(`secondary`, currentTarget);
                          comBosSecondaryDiagnosisOnChange(value);
                        }}
                      />
                    </div>
                    {isOpenDropDown ? (
                      <div
                        id={css.downshiftDropdown}
                        style={{
                          overflowY: `scroll`,
                          maxHeight: `140px`,
                        }}
                      >
                        {!secDiagnosisListItems.length ? (
                          <div
                            id={css.dropdownItem}
                            style={{
                              backgroundColor: `rgb(251 251 251)`,
                              fontWeight: `normal`,
                              padding: `1px`,
                              borderBottom: `1px solid lightgrey`,
                              cursor: `pointer`,
                              color: `red`,
                            }}
                          >
                            No Data Found
                          </div>
                        ) : payloadRedux?.secondaryDiagnosis === `` ? null : (
                          secDiagnosisListItems?.map((item, index) => (
                            <div
                              id={css.dropdownItem}
                              {...getItemProps({ key: index, index, item })}
                              style={{
                                backgroundColor: highlightedIndex === index ? `lightgrey` : `rgb(251 251 251)`,
                                fontWeight: selectedItem === item ? `bold` : `normal`,
                                padding: `1px`,
                                borderBottom: `1px solid lightgrey`,
                                cursor: `pointer`,
                              }}
                            >
                              {item?.Description}
                            </div>
                          ))
                        )}
                      </div>
                    ) : null}
                  </div>
                )}
              </Downshift>

              {/* <InputField
                  fullWidth={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {secondaryDiagnosisLoading && <InputSpinner size='sm' />}
                      </InputAdornment>
                    )
                  }}
                  size='small'
                  type="text"
                  label="Secondary Diagnosis Search"
                  onChange={(e) => {
                    const { target, currentTarget } = e
                    const { value } = target
                    anchorElemHandler(`secondary`, currentTarget)
                    comBosSecondaryDiagnosisOnChange(value)
                  }}
                  aria-label="diagnosis"
                  aria-describedby="basic-addon2"
                  value={payloadRedux?.secondaryDiagnosis}
                />
                <Menu open={isOpenDropDown && secDiagnosisListItems?.length} anchorEl={anchorElements?.secondary}>
                  {secDiagnosisListItems?.map((item, ind) => <MenuItem onClick={() => secondaryDiagnosisDropdownValueOnChange(item)} key={ind}>{item?.Description}</MenuItem>)}
                </Menu> */}
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                fullWidth={true}
                size="small"
                // autoComplete="off"
                value={payloadRedux?.baseLineAHI}
                type="text"
                // sx={{ width: `95%`, padding: `2%` }}
                name="baseLineAHI"
                label="BaseLine AHI"
                onChange={(e) => {
                  onChangeForValues(e);
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                fullWidth={true}
                size="small"
                // autoComplete="off"
                value={payloadRedux?.MRN}
                type="text"
                // sx={{ width: `95%`, padding: `2%` }}
                name="MRN"
                label="Medical Record Number"
                onChange={(e) => onChangeForValues(e)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Equipment Information */}
        <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
          Equipment Information
        </p>
        <hr style={{ margin: `0px` }} />

        {/*   End tags of Mask */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container columnSpacing={1} rowSpacing={1.5}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                fullWidth={true}
                size="small"
                autoComplete="off"
                value={payloadRedux?.device?.manufacturer === `reactHealth` ? `React Health` : payloadRedux?.device?.manufacturer}
                disabled
                type="text"
                name="deviceManufacturer"
                label="Device Manufacturer"
              />
            </Grid>
            {payloadRedux?.deviceManufacturer === `reactHealth` ? (
              <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                <InputField
                  fullWidth={true}
                  size="small"
                  autoComplete="off"
                  value={payloadRedux?._idFromDevice}
                  disabled
                  type="text"
                  name="deviceSerialNumber"
                  label="Device Number"
                />
              </Grid>
            ) : (
              <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                <InputField
                  fullWidth={true}
                  size="small"
                  autoComplete="off"
                  value={payloadRedux?.device?.deviceNumber || payloadRedux?.device?.serialNumber || payloadRedux?.deviceSerialNumber}
                  disabled
                  type="text"
                  name="deviceSerialNumber"
                  label="Device Number"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="deviceManufacturer">Mask Company</InputLabel>
                <Select
                  inputProps={{
                    ref: manufacturerRef,
                  }}
                  onBlur={() => validateInput(`manufacturer`)}
                  id="deviceManufacturer"
                  label="Mask Company"
                  labelId="deviceManufacturer"
                  onChange={(event) => {
                    onChangeForValues(event);
                  }}
                  value={payloadRedux?.mask?.manufacturer}
                  name="manufacturer"
                // sx={{ height: `40px` }}
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="resmed">Resmed</MenuItem>
                  <MenuItem value="respironics">Respironics</MenuItem>
                  <MenuItem value={`Fisher & Paykel`}>{`Fisher & Paykel`}</MenuItem>
                </Select>
              </FormControl>
              <div className="small text-danger">{errors.manufacturerError ? errors.manufacturerError : ``}</div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth={true}
                inputProps={{
                  ref: typeRef,
                }}
                error={errors.maskTypeError ? true : false}
                helperText={`${errors.maskTypeError ? errors.maskTypeError : ``}`}
                size="small"
                value={payloadRedux?.mask?.type}
                label="Mask Type"
                onChange={(e) => {
                  setPayLoadRedux({
                    ...payloadRedux,
                    mask: {
                      ...payloadRedux?.mask,
                      type: e.target.value,
                    },
                  });
                  setInputsToEdit({
                    ...inputsToEdit,
                    mask: {
                      ...inputsToEdit?.mask,
                      type: e.target.value,
                    },
                  });
                  setErrors({ ...errors, maskTypeError: `` });
                }}
                select
                name="type"
              >
                {payloadRedux?.mask?.manufacturer === `resmed`
                  ? resmedGroupedOptions.map((item, index) => [
                    <ListSubheader
                      sx={{
                        ...maskTypeSubHeaderStyles,
                      }}
                      key={index + `resmed`}
                    >
                      {item.label}
                    </ListSubheader>,
                    item.options.map((subItem, index) => (
                      <MenuItem
                        sx={{
                          ...maskTypeStyles,
                        }}
                        key={`key` + index}
                        value={subItem.value}
                      >
                        {subItem.label}
                      </MenuItem>
                    )),
                  ])
                  : payloadRedux?.mask?.manufacturer === `respironics`
                    ? respironicsGroupedOptions.map((item, index) => [
                      <ListSubheader key={index + `respironics` + item.label}>{item.label}</ListSubheader>,
                      item.options.map((subItem) => (
                        <MenuItem
                          sx={{
                            ...maskTypeStyles,
                          }}
                          key={index + `resKey`}
                          value={subItem.value}
                        >
                          {subItem.label}
                        </MenuItem>
                      )),
                    ])
                    : payloadRedux?.mask?.manufacturer === `Fisher & Paykel`
                      ? fisherAndPaykelGroupedOptions.map((item, index) => [
                        <ListSubheader key={`Paykel` + index}>{item.label}</ListSubheader>,
                        item.options.map((subItem) => (
                          <MenuItem
                            sx={{
                              ...maskTypeStyles,
                            }}
                            key={index + `key-Paykel`}
                            value={subItem.value}
                          >
                            {subItem.label}
                          </MenuItem>
                        )),
                      ])
                      : null}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <FormControl size="small" id="sizeMask" fullWidth>
                <InputLabel>Mask Size</InputLabel>
                <Select
                  inputProps={{
                    ref: sizeRef,
                  }}
                  error={errors.maskSizeError ? true : false}
                  id="sizeMask"
                  label="Mask Size"
                  onChange={(event) => {
                    onChangeForValues(event);
                  }}
                  onBlur={() => validateInput(`size`)}
                  value={payloadRedux?.mask?.size ? payloadRedux?.mask?.size : ``}
                  name="size"
                  sx={{ height: `40px` }}
                >
                  <MenuItem defaultValue value="">
                    Select
                  </MenuItem>
                  {payloadRedux?.mask?.manufacturer === `resmed`
                    ? resmedMaskTypes?.map((mask, ind) => (
                      <MenuItem value={mask} key={`${mask}${ind}`}>
                        {mask}
                      </MenuItem>
                    ))
                    : payloadRedux?.mask?.manufacturer === `respironics`
                      ? respironicsMaskTypes?.map((mask, ind) => (
                        <MenuItem value={mask} key={`${mask}${ind}`}>
                          {mask}
                        </MenuItem>
                      ))
                      : payloadRedux?.mask?.manufacturer === `Fisher & Paykel`
                        ? fisherMaskTypes?.map((mask, ind) => (
                          <MenuItem value={mask} key={`${mask}${ind}`}>
                            {mask}
                          </MenuItem>
                        ))
                        : null}
                </Select>
              </FormControl>
              <div className="small text-danger">{errors.maskSizeError ? errors.maskSizeError : ``}</div>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                fullWidth={true}
                size="small"
                value={payloadRedux?.emrPatientInternalId}
                type="text"
                name="emrPatientInternalId"
                label="Patient Id"
                onChange={(e) => {
                  onChangeForValues(e);
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                fullWidth={true}
                size="small"
                value={payloadRedux?.emrPatientExternalId}
                type="text"
                name="emrPatientExternalId"
                label="External Id"
                onChange={(e) => onChangeForValues(e)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <FormControl size="small" id="status" fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  id="patientStatus"
                  error={errors?.statusError ? true : false}
                  onBlur={() => validateInput(`status`)}
                  // id={payloadRedux?.status ? css.selectedSelector : css.selector}
                  onChange={(event) => {
                    // const { value } = event.target;
                    // Invoking the confirmation alert function with a selected value from the selection
                    alertForConfirmation(event);
                  }}
                  value={payloadRedux?.status?.helperStatus}
                  name="status"
                  inputProps={{
                    ref: statusRef,
                  }}
                >
                  <MenuItem defaultValue value="">
                    Select
                  </MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="autoReactivation">Auto Re-Activation</MenuItem>
                  <MenuItem value="recurringReactivation">Recurring Reactivation</MenuItem>
                </Select>
              </FormControl>
              <p className="d-block text-danger">{errors?.statusError ? errors?.statusError : ``}</p>

            </Grid>

            <Zoom in={payloadRedux?.status?.helperStatus === `autoReactivation`} mountOnEnter unmountOnExit>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <DatePickerCustomize
                  dateInputValue={payloadRedux?.status?.nextActivationOn || null}
                  isFutureSelection={true}
                  onChange={(date) => {
                    reactivationValueSetterForDate(`nextActivationOn`, date);
                  }}
                  pickerProps={{
                    disablePast: true,
                    label: `Reactivation Date`,
                    openTo: `month`,
                    views: [`year`, `month`, `day`],
                    style: { width: `100%` },
                    inputFormat: `MM/DD/YYYY`,
                    InputLabelProps: {
                      shrink: true,
                    },
                    size: `small`,
                    fullWidth: true,
                  }}
                />
              </Grid>
            </Zoom>
            <Zoom in={payloadRedux?.status?.helperStatus === `autoReactivation`} mountOnEnter unmountOnExit>
              <Tooltip
                title={
                  <>
                    <i style={{ fontSize: `10px` }}>The patient status will automatically activate on selected date.</i>
                  </>
                }
                placement="right"
                arrow
              >
                <button type="button" data-toggle="tooltip" data-placement="top" title="">
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                </button>
              </Tooltip>
            </Zoom>

            <Zoom in={payloadRedux?.status?.helperStatus === `recurringReactivation`} mountOnEnter unmountOnExit>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <MuiSelect
                  controlProps={{
                    size: `small`,
                    fullWidth: true,
                    id: `recurringReactivation`,
                  }}
                  label="Recurring Activation Frequency"
                  options={[...patientRecurringReactivationOptions]}
                  optionKey={`name`}
                  optionValue={`value`}
                  selectProps={{
                    label: `Recurring Activation Frequency`,
                    value: payloadRedux?.status?.recurringPeriod || ``,
                    name: `recurringReactivation`,
                    onChange: (event) => {
                      const {
                        target: { value },
                      } = event;
                      reactivationValueSetter(`recurringPeriod`, value);
                    },
                  }}
                />
              </Grid>
            </Zoom>
            <Zoom in={payloadRedux?.status?.helperStatus === `recurringReactivation`} mountOnEnter unmountOnExit>
              <Tooltip
                title={
                  <>
                    <i style={{ fontSize: `10px` }}>
                      The patient status will automatically active and inactive
                      <br />
                      according to the selected frequency.
                    </i>
                  </>
                }
                placement="right"
                arrow
              >
                <button type="button" data-toggle="tooltip" data-placement="top" title="">
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                </button>
              </Tooltip>
            </Zoom>
          </Grid>
        </Box>
        <div className="w-100">
          <div className="d-flex flex-wrap">
            {payloadRedux?.deactivatedAt && (
              <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                <label htmlFor="integrationStatus" id={css.customLabel}>
                  Inactivated on:
                </label>
                <h4 style={{ padding: `3.7% 0%`, marginLeft: `10px`, fontSize: `17px` }}>
                  {payloadRedux?.deactivatedAt ? momentWrapper(payloadRedux?.deactivatedAt).format(`MM-DD-YYYY`) : `No record`}
                </h4>
              </div>
            )}
          </div>
        </div>
        <div className="w-100">
          <div className="d-flex flex-wrap">
            {typeof payloadRedux?.reactivateAt === `string` && selectedPatient?.status === `inactive` && (
              <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                <label htmlFor="integrationStatus" id={css.customLabel}>
                  Date Of Re-activation
                </label>
                <h4 style={{ padding: `3.7% 0%`, marginLeft: `10px`, fontSize: `17px` }}>
                  <DateFormat date={payloadRedux?.reactivateAt} />
                </h4>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-wrap pb-3">
          <div className="w-100"></div>
        </div>
        <div className="d-flex flex-wrap pb-3">
          <div className="w-100">
            <label htmlFor="note" id={css.customLabel}>
              Notes
            </label>
            <textarea
              rows="5"
              ref={notesRef}
              style={{ borderColor: `#dddddd`, width: `98%` }}
              value={payloadRedux?.notes}
              onChange={(event) => {
                onChangeForValues(event);
              }}
              name="notes"
            />
          </div>
          <p className="text-danger">{errors.notesError ? errors.notesError : ``}</p>
        </div>
      </div>

      {loading && <PuffSpinner />}
    </CustomizedDialogs>
  );
};
