/* eslint-disable no-case-declarations */
import {
  ADD_RISK_NOTIFICATIONS,
  INITIALIZE_SMS_ARRAY_FOR_NOTIFICATION,
  INCOMMING_TWILIO_SMS,
  REMOVE_SMS_ARRAY_FOR_NOTIFICATION,
  OUTGOING_TWILIO_SMS,
  MD_CLINICAL_STAFF_COMMON_SMS_THREAD,
  SMS_ARRAY_FOR_NOTIFICATION,
  REMOVE_INCOMMING_TWILIO_SMS_FROM_NOTIFICATION_ARRAY,
  REMOVE_VM_NOTIFICATION,
} from './twilioTypes';

const initialState = {
  twilioInCommingSMS: {},
  twilioOutGoingSMS: {},
  mDClinicalStaffCommonSmsThread: {},
  smsArrayForNotification: [],
  arrayRerenderFlag: false,
  riskNotifications: [],
  removeVmNotification: '',
};

export const twilioReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_VM_NOTIFICATION:
      return { ...state, removeVmNotification: action.payLoad };
    case INCOMMING_TWILIO_SMS:
      return {
        ...state,
        twilioInCommingSMS: action.payload,
      };
    case ADD_RISK_NOTIFICATIONS:
      return {
        ...state,
        riskNotifications: action.payload,
      };
    case OUTGOING_TWILIO_SMS:
      return {
        ...state,
        twilioOutGoingSMS: action.payload,
      };
    case REMOVE_SMS_ARRAY_FOR_NOTIFICATION:
      return {
        ...state,
        smsArrayForNotification: [],
      };
    case INITIALIZE_SMS_ARRAY_FOR_NOTIFICATION:
      return {
        ...state,
        smsArrayForNotification: action.payload,
      };
    case SMS_ARRAY_FOR_NOTIFICATION:
      let tempArray = [];

      let updatedTwilio =
        state.smsArrayForNotification?.length > 0
          ? state.smsArrayForNotification.filter((msg) => {
            return action?.payload?.from !== msg?.from;
          })
          : action.payload;
      if (state.smsArrayForNotification?.length > 0) {
        tempArray = [...updatedTwilio, action.payload];
      } else {
        tempArray = [action.payload];
      }
      // }
      // else{
      //   tempArray.push(action.payload);
      //   }
      return {
        ...state,
        smsArrayForNotification: [...tempArray],
      };

    case `NOTIFICATION_FROM_PATIENT_MESSAGE`:
      let eventData = action.payLoad;
      let patientIdTwillio = eventData?.from;
      let patientId = eventData?.patientId;

      if (patientId && window.location?.pathname === `/patient` && patientId === patientIdTwillio) {
        return { ...state, smsArrayForNotification: state.smsArrayForNotification.filter((sms) => sms?.from !== patientIdTwillio) };
      }
      return { ...state, smsArrayForNotification: [...state.smsArrayForNotification.filter((sms) => sms?.from !== patientIdTwillio), { ...eventData }] };

    case `REMOVE_CURRENT_MESSAGE_PATIENT`:
      return { ...state, smsArrayForNotification: state.smsArrayForNotification.filter((sms) => sms?.from !== action.payLoad) };
    case REMOVE_INCOMMING_TWILIO_SMS_FROM_NOTIFICATION_ARRAY:
      let _tempArray = [];

      _tempArray = state.smsArrayForNotification?.filter((obj) => obj.from !== action.payload);

      return {
        ...state,
        smsArrayForNotification: _tempArray,
        arrayRerenderFlag: !state.arrayRerenderFlag,
      };
    case MD_CLINICAL_STAFF_COMMON_SMS_THREAD:
      return {
        ...state,
        mDClinicalStaffCommonSmsThread: action.payload,
      };

    default:
      return state;
  }
};
