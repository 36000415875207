import React, { useEffect, useState } from 'react';
import { patchRequest, PATIENT_UPDATE_URL } from 'src/crud/crud';
import { useUserConditionalRendering } from 'src/pages/common/hooks/useUserConditionalRendering';

import css from './basicDetails.module.css';
import { useSelector } from 'react-redux';

const RenderNotes = () => {
  const [notes, setNotes] = useState(``);

  const [notesUpdatingFlag, setNotesUpdatingFlag] = useState(`none`);

  const patientData = useSelector((state) => state.patient.patientData);

  const patientNotes = patientData?.data?.notes

  const patientId = patientData?.data?._id



  useEffect(() => {
    if (patientId && patientNotes) setNotes(patientNotes)

    return () => {
      setNotes(``);
      setNotesUpdatingFlag(`none`);
    };
  }, [patientNotes, patientId]);

  const autoUpdateNotes = () => {
    let url = PATIENT_UPDATE_URL + patientId;
    setNotesUpdatingFlag(`updating`);
    patchRequest(url, {
      notes,
    })
      .then(() => {
        setNotesUpdatingFlag(`done`);
      })
      .catch(() => {
        setNotesUpdatingFlag(`error`);
      });
  };
  return (
    <div className="row mt-1">
      <div className="col-12">
        <label htmlFor="note" id={css.customLabel}>
          Notes{` `}
          <span style={{ color: `grey`, fontSize: `x-small` }}>
            {notesUpdatingFlag === `updating`
              && `Saving...`}
            {notesUpdatingFlag === `done`
              && `Saved.`}
            {notesUpdatingFlag === `error`
              && `Error !`}

          </span>
        </label>
        <textarea
          rows="3"
          style={{ borderColor: `#dddddd`, width: `100%` }}
          name="note"
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          onBlur={autoUpdateNotes}
        />
      </div>
    </div>
  );
};

export const SaveNotes = ({ notesFromRedux, userId }) => {
  const prepareNotesProps = {
    notesFromRedux,
    userId,
  };
  const renderNotes = useUserConditionalRendering({
    superAdminAndProvider: <RenderNotes {...prepareNotesProps} />,
    superAdminAndClinicalStaff: <RenderNotes {...prepareNotesProps} />,
    provider: <RenderNotes {...prepareNotesProps} />,
    clinicalStaff: <RenderNotes {...prepareNotesProps} />,
  });

  return renderNotes;
};
