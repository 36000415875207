/* eslint-disable no-prototype-builtins */
import React, { useEffect, useRef, useState } from 'react';
import css from './billerRoleTable.module.css';
import {
  GET_REPORTS_BY_STATUS_BILLER_ROLE, getRequest, UPDATE_REPORT_BILL_STATUS, patchRequest,
  UPDATE_ALL_REPORT_BILL_STATUS,
  postRequest,
  SEND_TO_EMR,
} from '../../../../../../crud/crud';
import { PuffSpinner } from '../../../../../common/components/spinner/puff/puff';
import Swal from 'sweetalert2';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TableFooter } from 'src/pages/common/hooks/TableFooter';
import { capitalizeFirstLetter, EMR_StatusPill, taskStatusPillCol } from 'src/utils';
import { useStartEndDateSelction } from 'src/pages/common/hooks/useStartEndDateSelction';
import { Box, Stack } from '@mui/material';
import { MuiPill } from 'src/pages/common/components/BadgePill/BadgePill';
import { CapitalizeFirstLetterComp } from 'src/pages/common/HOC/CapitalizeFirstLetterComp';
import { Tooltip } from '@mui/material';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import axios from 'axios';
import { useSelector } from 'react-redux';

export const BillerRoleTable = ({ csvButtonDisabled, createBillingCsvFile }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [loadingBilled, setLoadingBilled] = useState({});
  const renderTotalRep = useRef(null);
  const [combineFilteredReports, setCombineFilteredReports] = useState([]);
  const [allBillReportFlagCheck, setAllBillReportFlagCheck] = useState(false);
  const [allSendToEMRFlagCheck, setAllSendToEMRFlagCheck] = useState(false);
  const lastMonth = moment().subtract(1, `months`);
  const [dateRangeSelection, setDateRangeSelection] = useState({ startDate: lastMonth, endDate: moment(lastMonth).endOf(`month`) });
  const { startDate, endDate } = dateRangeSelection;
  const [reportStatus, setReportStatus] = useState(`READY_TO_BILL`);
  const [fetchReportsFlag, setFetchReportsFlag] = useState(0);
  const userPracticeId = useSelector((state) => state?.user?.user?._practiceId);

  const idsToCheck = ['633c218c313c85bec50b694e', '63748f7132558ced1682c674', '66f3aa53eecac808ae7d848c'];
  const shouldShowEmrConfigs = idsToCheck.some(id => userPracticeId?.includes(id));

  let monthArray = [``, `January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`];
  const dataMapHelper = async (response) => {
    let billerReports = response?.data?.data.map((e) => {
      let month = e.month.toString();
      let array = month.split(``);
      let isGreater = array[array.length - 2] + array[array.length - 1];
      let currentMonthIndex;
      if (parseInt(isGreater) >= 10) {
        let currentMonth = monthArray[parseInt(isGreater)];
        let reportDate = currentMonth + ` ` + array.slice(0, 4).join(``);
        return { ...e, month: reportDate };
      } else {
        currentMonthIndex = array[array?.length - 1];
        let currentMonth = monthArray[parseInt(currentMonthIndex)];
        let reportDate = currentMonth + ` ` + array.slice(0, 4).join(``);
        return { ...e, month: reportDate };
      }
    });
    return billerReports;
  };
  const mappingPromise = (response) => {
    let mappedData = new Promise((resolve, reject) => {
      let mappedData = dataMapHelper(response);
      resolve(mappedData);
      reject(`rejected`);
    });
    mappedData.then((res) => {
      setCombineFilteredReports(res);
    });
  };

  useEffect(() => {
    const source = axios.CancelToken.source()

    if (fetchReportsFlag || !fetchReportsFlag) {
      setLoading(true);

      let decidedDates = `&startDate=${moment(startDate).format(`YYYY-MM`)}&endDate=${moment(endDate).format(`YYYY-MM`)}`;

      const getReports = () => {
        let query = `${GET_REPORTS_BY_STATUS_BILLER_ROLE}/?pageNumber=${currentPage}&pageSize=${rowsPerPage}${decidedDates}&status=${reportStatus}`;
        getRequest(query, { cancelToken: source.token })
          .then((response) => {
            setLoading(false);
            mappingPromise(response);
            setTotalRows(response?.data?.pager?.totalRecords);
            setTotalPage(Math.ceil(response?.data?.pager?.totalRecords / parseInt(response?.data?.pager?.pageSize)));
            renderTotalRep.current.innerHTML = response?.data?.pager?.totalRecords;
          })
          .catch((error) => {
            setLoading(false);
          });
      };
      getReports();
    }
    return () => source.cancel()
  }, [reportStatus, fetchReportsFlag, dateRangeSelection, currentPage, rowsPerPage]);

  useEffect(() => {
    const emrStatusHandler = ({ _id: id, emrData }) => {
      setCombineFilteredReports((prev) =>
        prev.map((report) => {
          if (report._id === id) {
            return { ...report, emrData };
          }
          return report;
        })
      );
    };

    newSocket.on('SEND_REPORT_TO_EMR', emrStatusHandler);

    return () => {
      newSocket.off('SEND_REPORT_TO_EMR', emrStatusHandler);
    };
  }, []);


  // HANDLING CHECKBOX

  const billedHandler = (e, reportId, actionObj) => {
    e.target.disabled = true;
    setLoadingBilled(prev => ({ ...prev, [reportId]: true }));

    patchRequest(UPDATE_REPORT_BILL_STATUS + reportId, {})
      .then(() => {
        setLoadingBilled(prev => ({ ...prev, [reportId]: false }));
        setFetchReportsFlag((p) => p + 1);
      })
      .catch(() => {
        setLoadingBilled(prev => ({ ...prev, [reportId]: false }));
      });
  };

  const resetPagination = () => {
    if (currentPage !== 1) setCurrentPage(1);
    if (rowsPerPage !== 10) setRowsPerPage(10);
    setFetchReportsFlag((p) => p + 1);
  };

  // HANDLE ALL BILLED LIST UPDATE
  const billedAllHandler = () => {
    if (combineFilteredReports.length) {
      setAllBillReportFlagCheck(true);
      const billedId = combineFilteredReports?.map((report) => report._id);
      Swal.fire({
        title: `Are you sure?`,
        text: `You want to bill all reports!`,
        icon: `warning`,
        showCancelButton: true,
        confirmButtonColor: `#3085d6`,
        cancelButtonColor: `#d33`,
        confirmButtonText: `Yes, bill it!`,
      }).then((result) => {
        if (result.isConfirmed) {
          const payLoad = { reportsIds: billedId };
          patchRequest(UPDATE_ALL_REPORT_BILL_STATUS, payLoad)
            .then(() => {
              setCombineFilteredReports([]);
              renderTotalRep.current.innerHTML = 0;
              Swal.fire(`Success!`, `All Reports Billed`, `success`);
            })
            .catch((err) => { });
        } else {
          setAllBillReportFlagCheck(false);
        }
      });
    }
  };

  // HANDLE ALL EMR LIST UPDATE
  const sendAllToEMRHandler = () => {

    if (combineFilteredReports.length) {
      setAllSendToEMRFlagCheck(true);
      setLoading(true);
      const EMRIds = combineFilteredReports?.map((report) => report._id);
      Swal.fire({
        title: `Are you sure?`,
        text: `You want to send to EMR all reports!`,
        icon: `warning`,
        showCancelButton: true,
        confirmButtonColor: `#3085d6`,
        cancelButtonColor: `#d33`,
        confirmButtonText: `Yes, send to EMR!`,
      }).then((result) => {
        if (result.isConfirmed) {
          const payLoad = { reportsIds: EMRIds };
          postRequest(SEND_TO_EMR, payLoad)
            .then((res) => {

              setAllSendToEMRFlagCheck(false);
              setLoading(false);
              Swal.fire(`Success!`, `${res.data.message}`, `success`);
            })
            .catch((err) => { });
        } else {
          setAllSendToEMRFlagCheck(false);
          setLoading(false);
        }
      });
    }
  };

  let renderdateRangeSelection = useStartEndDateSelction({
    startDate: dateRangeSelection?.startDate,
    endDate: dateRangeSelection?.endDate,
    resetDef: !!fetchReportsFlag,
    spacings: {
      columnSpacing: 1,
      rowSpacing: 1,
    },
    datePickerContProps: {
      styles: {
        mt: 1,
      },
    },
    pickerProps: {
      startSign: `Start Month`,
      endSign: `End Month`,
    },
    actionProps: {
      btnAction: (startDate, endDate) => {
        setDateRangeSelection({ startDate, endDate });
        resetPagination();
      },

      btnStyles: {
        padding: `5px`,
        width: `100%`,
      },
      text: `Fetch Reports`,
    },
  });



  const handleSendToEmr = (reportData) => {
    const reportId = reportData._id
    setLoadingStates(prev => ({ ...prev, [reportId]: true }));

    let payload = {
      reportsIds: [reportData._id],
    };

    postRequest(SEND_TO_EMR, payload)
      .then((response) => {
        setLoadingStates(prev => ({ ...prev, [reportId]: false }));
        successToast(response.data.message);
      })
      .catch(() => {
        setLoadingStates(prev => ({ ...prev, [reportId]: false }));
      });
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', backgroundColor: 'white' }} >
        <Box sx={{ p: 2, }}>
          <Stack direction="row" justifyContent={`space-between`} alignItems="center" spacing={1}>
            <Box>
              <h6 id={css.headText}>
                TOTAL BILLING REPORTS (<span ref={renderTotalRep}></span>)
              </h6>
            </Box>
            <Box>{renderdateRangeSelection}</Box>
            <Box>
              <Stack spacing={1} direction="column" justifyContent={`space-between`}>
                <Box>
                  <select
                    type="button"
                    onChange={({ target: { value } }) => {
                      setReportStatus(value);
                      resetPagination();
                    }}
                    disabled={csvButtonDisabled}
                    className="btn btn-outline-info btn-md"
                    style={{ fontSize: `9px`, width: `100%` }}
                  >
                    <option value="READY_TO_BILL">Ready to Bill Reports</option>
                    <option value="BILLED">Billed</option>
                  </select>
                </Box>
                <Box>
                  <button
                    type="button"
                    onClick={() =>
                      createBillingCsvFile(
                        reportStatus,
                        dateRangeSelection.startDate ? moment(dateRangeSelection.startDate).startOf(`day`).format(`YYYY-MM`) : null,
                        dateRangeSelection.endDate ? moment(dateRangeSelection.endDate).endOf(`day`).format(`YYYY-MM`) : null
                      )
                    }
                    disabled={csvButtonDisabled}
                    className="btn btn-outline-info btn-md"
                    style={{ fontSize: `9px`, width: `100%` }}
                  >
                    Download CSV
                  </button>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <div style={{ position: `relative`, overflowY: 'auto', height: '60vh' }}>
          <table className="table table-striped">
            <thead style={{ borderBottom: `1px solid grey`, position: 'sticky', top: -1, zIndex: 1 }}>
              <tr>
                <th ></th>
                <th>
                  LAST NAME
                </th>
                <th  >
                  FIRST NAME
                </th>
                <th  >
                  Month
                </th>

                {reportStatus === 'BILLED' ? null : <th>
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <span>
                      Mark all as billed
                    </span>
                    <input type="checkbox" checked={allBillReportFlagCheck || reportStatus === 'BILLED'} onChange={() => billedAllHandler()} />
                  </div>
                </th>}

                {shouldShowEmrConfigs && (
                  <>
                    <th>
                      <div style={{ display: 'flex', gap: '1rem' }}>
                        <span>Send to EMR</span>
                        <input
                          type="checkbox"
                          checked={allSendToEMRFlagCheck}
                          onChange={() => sendAllToEMRHandler()} />
                      </div>
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      EMR Status
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody style={{ height: `90%`, overflowY: `auto`, }}>
              {combineFilteredReports?.length ? (
                combineFilteredReports?.map((report) => (
                  <tr key={report?._id}>
                    <td className={css.customCol0}></td>
                    <td className="text-capitalize" >
                      {capitalizeFirstLetter(`${report?.patient?.lastName} `)}
                    </td>
                    <td className="text-capitalize" >
                      {capitalizeFirstLetter(`${report?.patient?.firstName}`)}
                      {` `}
                      {report?.reportStatus === `ADDENDUMED` ? <span className="badge badge-pill badge-info">A</span> : null}
                    </td>
                    <td >{report?.month}</td>

                    {reportStatus === 'BILLED' ? null : <td>
                      <button
                        id={css.headButtonForERM}
                        className="btn btn-info h-25"
                        onClick={(e) => billedHandler(e, report._id, report?.isDuplicated ? { isAddendumBilled: true } : { isBilled: true })}
                        disabled={loadingBilled[report._id]}
                      >
                        {loadingBilled[report._id] ? 'Loading...' : 'Mark as billed'}
                      </button>

                    </td>}

                    {shouldShowEmrConfigs && (
                      <>
                        <td>
                          <button
                            id={css.headButtonForERM}
                            className="btn btn-info h-25"
                            onClick={() => handleSendToEmr(report)}
                            disabled={loadingStates[report._id] || report?.emrData?.status === `SENT`}
                          >
                            {loadingStates[report._id] ? 'Sending To EMR...' : 'Send To EMR'}
                          </button>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {report?.emrData?.status ? <EMRstatusPill emrStatus={report?.emrData?.status} ermData={report?.emrData} /> : '-'}
                        </td>
                      </>
                    )}

                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" style={{ textAlign: `center` }}>
                    No Record Found.
                  </td>
                </tr>
              )}
            </tbody>

            <TableFooter
              style={{ position: 'sticky', bottom: -1, zIndex: 1 }}
              totalPage={totalPage}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalRows}
              setRowsPerPage={setRowsPerPage}
              setCurrentPage={setCurrentPage}
              pagination={null}
              setPagination={null}
              isPatientView={false}
              data={combineFilteredReports}
            />
          </table>
          {loading ? <PuffSpinner /> : null}
        </div>
      </div>
    </div>

  );
};

const EMRstatusPill = ({ emrStatus, ermData }) => {
  const [status, setStatus] = useState('');

  useEffect(() => {
    setStatus(emrStatus);
  }, [emrStatus]);

  const renderTooltipContent = () => (
    <div style={{ padding: '8px', maxWidth: '200px' }}>
      {ermData?.failedCodes?.length > 0 && (
        <>
          <div style={{ fontWeight: 'bold' }}>Failed Codes:</div>
          <div>{ermData.failedCodes.join(', ')}</div>
        </>
      )}
      {ermData?.sentCodes?.length > 0 && (
        <>
          <div style={{ fontWeight: 'bold', marginTop: '8px' }}>Sent Codes:</div>
          <div>{ermData.sentCodes.join(', ')}</div>
        </>
      )}
      {(ermData?.sentCodes?.length === 0 && ermData?.failedCodes?.length === 0) && (
        <div style={{ fontWeight: 'bold', marginTop: '8px' }}>
          <strong>Reason:</strong> {ermData.reason}
        </div>
      )}
    </div>
  );

  return (
    <Tooltip title={renderTooltipContent()} arrow placement="top">
      <div style={{ display: 'inline-block' }}>
        <MuiPill
          pillProps={{
            sx: {
              backgroundColor: EMR_StatusPill[status],
              color: 'white',
              borderRadius: 'none',
              fontSize: '10px',
            },
            size: 'small',
          }}
        >
          <CapitalizeFirstLetterComp>{status}</CapitalizeFirstLetterComp>
        </MuiPill>
      </div>
    </Tooltip>
  );
}
